import React from 'react';
import "../../common/Navbar.css";
import {Link} from "react-router-dom";
import biodome_ci_w from "../../../resources/biodome_ci_w.svg";
import {inject} from "mobx-react";
import {api_biodome} from "../../../api/api_biodome";

type Props = {
  props: any
}

class Header extends React.Component<Props, any> {

  logout = () => {
    try {
      api_biodome.delete("/v1/auth/session").then((r) => {
        // if (!r.ok) return alert('로그아웃 실패');
        api_biodome.deleteHeader("Authorization");
        try {
          typeof this.props.sessionStore?.reset === "function" &&
          this.props.sessionStore?.reset();
        } catch (e) {}
        try {
          sessionStorage.removeItem("Authorization");
          localStorage.removeItem("Authorization");
        } catch (e) {
          console.warn(e);
        }
        this.props.t.history.push("/admin/sign-in");
      });
    } catch (e) {
      console.error(e);
      alert("오류발생");
      this.props.t.history.push("/");
    }
  }

  render() {
    return (
      <div id="header" style={{position: 'relative'}}>
        <div className="header-in">
          <div className="logo admin-logo">
            <div>
              <Link to={'/admin'}><img src={biodome_ci_w} width="110px" alt="Biodome"/></Link>
              <span className={"admin-text"}>ADMIN</span>
            </div>
            {/* to={"/admin/sign-in"} */}
            <div className={"logout"} onClick={this.logout}>로그아웃</div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(['sessionStore'])(Header);