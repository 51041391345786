import React, {useState, useMemo, useCallback, useEffect} from "react";
import "./index.css";
import "./../typewriter.css";
import "swiper/swiper-bundle.css";
// import { Parallax } from 'react-scroll-parallax';

import SwiperCore, { Parallax, Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { FullPage, Slide } from "react-full-page";

import "css-doodle";

import AOS from "aos";
import "aos/dist/aos.css";

import protect_icon01 from "../../resources/protect_icon01.svg";
import safety_icon01 from "../../resources/safety_icon01.svg";
import continue_icon01 from "../../resources/continue_icon01.svg";
import scroll_icon01 from "../../resources/scroll_icon01.svg";
import biospace from "../../resources/01/biospace.jpg";
import dallas_Cowboys from "../../resources/01/dallas_Cowboys.png";
import jetlinx1 from "../../resources/01/jetlinx1.jpg";
import lynd from "../../resources/01/lynd.png";
import mayo from "../../resources/01/mayo.png";
import nets_b from "../../resources/01/nets_b.png";
import Nj_Police from "../../resources/01/Nj_Police.png";
import N_Roch_school from "../../resources/01/N_Roch_school.png";
import nyc_c_a from "../../resources/01/nyc_c_a.png";
import paramount from "../../resources/01/paramount.png";
import PaymentsJournal2 from "../../resources/01/PaymentsJournal2.jpg";
import PR_Newswire from "../../resources/01/PR_Newswire.jpg";
import provisioner from "../../resources/01/provisioner.jpg";
import R_P_S from "../../resources/01/R_P_S.png";
import Red_Bull from "../../resources/01/Red_Bull.png";
import wfmz69 from "../../resources/01/wfmz69.jpg";
import alliance_im01 from "../../resources/01/alliance_im01.png";
import alliance_im02 from "../../resources/01/alliance_im02.png";
import alliance_im03 from "../../resources/01/alliance_im03.png";
import alliance_im04 from "../../resources/01/alliance_im04.png";
import alliance_im05 from "../../resources/01/alliance_im05.png";
import alliance_im06 from "../../resources/01/alliance_im06.png";
import alliance_im07 from "../../resources/01/alliance_im07.png";
import alliance_im08 from "../../resources/01/alliance_im08.png";
import alliance_im09 from "../../resources/01/alliance_im09.png";
import alliance_im10 from "../../resources/01/alliance_im10.png";
import alliance_im11 from "../../resources/01/alliance_im11.png";
import alliance_im12 from "../../resources/01/alliance_im12.png";
import alliance_im13 from "../../resources/01/alliance_im13.png";

import certifi_01 from "../../resources/certifi_01.png";
import certifi_02 from "../../resources/certifi_02.png";
import certifi_03 from "../../resources/certifi_03.png";
import certifi_04 from "../../resources/certifi_04.png";
import certifi_05 from "../../resources/certifi_05.png";
import certifi_06new from "../../resources/certifi_06new.png";
import certifi_07 from "../../resources/certifi_07.png";
import certifi_08 from "../../resources/certifi_08.png";
import certifi_09 from "../../resources/certifi_09.png";
import certifi_10 from "../../resources/certifi_10.png";
import certifi_11 from "../../resources/certifi_11.png";
import certifi_12new from "../../resources/certifi_12new.png";

import science_im01 from "../../resources/science_im01.png";
import science_im02 from "../../resources/science_im02.png";
import science_im03 from "../../resources/science_im03.png";
import science_im04 from "../../resources/science_im04.png";
import science_im05 from "../../resources/science_im05.png";
import science_im06 from "../../resources/science_im06.png";

import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
AOS.init();
SwiperCore.use([Parallax, Autoplay, Pagination, Navigation]);

const Index = () => {
  const [swiper, setSwiper] = useState(null);
  const renderBubble = () => (
    <div className="area">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );

  const swiperParams = useMemo(
    () => ({
      lazy: false,
      longSwipesRatio: 0.35,
      autoplay: { delay: 3000, disableOnInteraction: false },
      onSwiper: setSwiper,
      parallax: true,
      loop: true,
      passiveListeners: true,
      preloadImages: false,
      preventInteractionOnTransition: true,
      roundLengths: false,
      slidesPerView: 1,

      speed: 1500,
    }),
    [setSwiper]
  );

  const onNext = useCallback(() => {
    if (swiper) {
      swiper.slideNext(1680);
    }
  }, [swiper]);
  const onPrev = useCallback(() => {
    if (swiper) {
      swiper.slidePrev(1680);
    }
  }, [swiper]);

  const [pageMode, setPageMode] = useState('full-page');

  const [scrollPosition, setScrollPosition] = useState(0);
  const [savedPosition, setSavedPosition] = useState(0);

  window.addEventListener('scroll', (e) => {
    setScrollPosition(window.scrollY);
  })

  useEffect(() => {
    if(savedPosition >= scrollPosition && window.screen.width <= 480) {
      setPageMode('full-page');
    }
  }, [scrollPosition]);

  return (
    <FullPage
      controls
      scrollMode={pageMode}
      beforeChange={(e) => {
        if(window.screen.width <= 480) {
          setPageMode('normal');
        }
        // if((e.from === 3 && e.to === 4) || (e.from === 4 && e.to === 3)) {
        //   setSavedPosition(scrollPosition);
        //
        // }
      }}
    >
      <Navbar />
      {renderBubble()}
      <Slide className="HSworp HS01">
        <div className="demo-slider-wrapper">
          <Swiper {...swiperParams}>
            <SwiperSlide className="HSworp HS01">
              <div className="slider-image-slide">
                <div
                  className="slider-image-slide-inner"
                  data-swiper-parallax="50%"
                  // data-swiper-parallax-scale="1.3"
                >
                  <div className="slider-image-slide-img img1 type-a">
                    <div className="Hslide_in">
                      <p data-aos="fade-up" data-aos-delay="300">
                        biodome은 독보적인 항균코팅 기술로 글로벌 방역시장을
                        리드하는
                      </p>
                      <h1 data-aos="fade-up" data-aos-delay="600">
                        ViaClean Technologies,LLC.(U.S.A) KOREA의
                      </h1>
                      <p data-aos="fade-up" data-aos-delay="900">
                        새로운 이름입니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="HSworp HS01">
              <div className="slider-image-slide">
                <div
                  className="slider-image-slide-inner"
                  data-swiper-parallax="50%"
                  // data-swiper-parallax-scale="1.3"
                >
                  <div className="slider-image-slide-img img2 type-a">
                    <div className="Hslide_in">
                      <p data-aos="fade-up" data-aos-delay="200">
                        미국내 대표적 생명공학기업 인 ViaClean
                        Technologies,LLC.(U.S.A) 의{" "}
                      </p>
                      <h1 data-aos="fade-up" data-aos-delay="600">
                        최첨단 나노항균코팅
                      </h1>
                      <p data-aos="fade-up" data-aos-delay="900">
                        기술은 미국(EPA.FDA.NSF..)뿐만 아니라 GVN연구소에 의해
                        검증.인증 되었습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="HSworp HS01">
              <div className="slider-image-slide">
                <div
                  className="slider-image-slide-inner"
                  data-swiper-parallax="50%"
                  // data-swiper-parallax-scale="1.3"
                >
                  <div className="slider-image-slide-img img3 type-a">
                    <div className="Hslide_in">
                      <p data-aos="fade-up" data-aos-delay="200">
                        ViaClean Technologies,LLC.(U.S.A) 의
                      </p>
                      <h1 data-aos="fade-up" data-aos-delay="600">
                        {" "}
                        BIOPROTECTUs System은 코로나바이러스의 멸균 효능까지
                      </h1>
                      <p data-aos="fade-up" data-aos-delay="900">
                        {" "}
                        입증되었으며 보다 안전한 공간을 설계(보장)합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className={"test"}>
            <div
              style={{
                width: "80%",
                height: "100vh",
                margin: "auto",
                maxWidth: "1224px",
              }}
            >
              <div className="btn-container-p1 ">
                <div className="slider-btn-prev " onClick={onPrev}>
                  {"<"}
                </div>
                <div className="slider-btn-next " onClick={onNext}>
                  {">"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>

      <Slide className="HSworp HS02">
        <div className="Hslide_in">
          <ul>
            <li>
              <p data-aos="fade-left" data-aos-delay="100" className="Htitle">
         PROTECT
              </p>
              {/* <h1 data-aos="fade-up" data-aos-delay="250" className="Hsubtitle">
                <span className="Hgreenfont">BIO</span>PROTECT
                <span className="Hgreenfont">Us</span> System..
              </h1> */}
              <h2 data-aos="fade-up" data-aos-delay="300" className="Hcopy">
                광범위한 살균.항균 기능은 다공성.비다공성의 모든 표면에 적용되며
                유해 박테리아 및 바이러스의 감염(오염)을 차단하고 보호합니다.
              </h2>
              <br />
              <br />
              <img
                data-aos="fade-up"
                data-aos-delay="1000"
                src={protect_icon01}
                width="140px"
                alt="Biodome"
              />
            </li>
            <li data-aos="fade-left" data-aos-delay="1000"></li>
          </ul>
        </div>

        <div className="king">
          <css-doodle>
            {`
           @grid: 20x1 / 50vmin;

           :container {
             perspective: 23vmin;
           }

           background: @m(@r(200, 240),
             radial-gradient(@p(#00b8a9, #f8f3d4, #0b5394, #0b5394) 15%,
               transparent 50%) @r(100%) @r(100%) / @r(1%, 3%) @lr no-repeat);
           @size: 130%;
           @place-cell: center;
           border-radius: 50%;
           transform-style: preserve-3d;
           animation: scale-up 20s linear infinite;
           animation-delay: calc(@i * -.4s);

           @keyframes scale-up {
             0% {
               opacity: 0;
               transform: translate3d(0, 0, 0) rotate(10);
             }

             10% {
               opacity: .3;
             }

             95% {
               transform:
                 translate3d(0, 0, @r(5vmin, 15vmin)) rotate(@r(-360deg, 360deg));
             }

             100% {
               opacity: 0;
               transform: translate3d(0, 0, 0vmin);
             }
           }
           `}
          </css-doodle>
        </div>

        <div className="Hscroll">
          Scroll Down
          <br />
          <br />
          <img src={scroll_icon01} alt="scroll" />
        </div>
        <div className="Hlefttext" data-aos="fade-right" data-aos-delay="2000">
          <div className="Hlefttextin ">       {" "}
                보호하다{" "}</div>
        </div>
      </Slide>

      <Slide className="HSworp HS03">
        <div className="Hslide_in">
          <ul>
            <li>
              <p
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-delay="200"
                className="Htitle"
              >SAFETY

              </p>
              {/* <h1 data-aos="fade-up" data-aos-delay="250" className="Hsubtitle">
                <span className="Hgreenfont">BIO</span>PROTECT
                <span className="Hgreenfont">Us</span> System..
              </h1> */}
              <h2 data-aos="fade-up" data-aos-delay="300" className="Hcopy">
                EPA(87583-3)및 FDA 규정을 준수 특허 받은 친환경 수성항균
                보호제(BIOPROECT500/BIOPROECTRTU) 로 인체에 무해합니다.
                <br />
                세균 및 바이러스는 접촉즉시 사멸되고 쾌적하고 안전한 공간을
                제공합니다.
              </h2>
              <br />
              <br />
              <img
                data-aos="fade-left"
                data-aos-delay="350"
                src={safety_icon01}
                width="140px"
                alt="safety"
              />
            </li>
            <li data-aos="fade-right" data-aos-delay="1000"></li>
          </ul>
        </div>
        <div className="Hscroll">
          Scroll Down
          <br />
          <br />
          <img src={scroll_icon01} alt="scroll" />
        </div>
        <div className="Hlefttext" data-aos="fade-right" data-aos-delay="2000">
          <div className="Hlefttextin">   {" "}
                안전하다{" "}</div>
        </div>
        <div className="king01">
          <css-doodle>
            {`
    :doodle {
      @grid: 45x1 / 40vmin;
      position: relative;
      z-index: 1;
    }
    :container {
      transform: translate(50%, 33vmin)
    }
    :after, :before {
      content: '';
      @place-cell: center;
      @size: 130%;
      background: radial-gradient(
        @p(#47BD9C, #0066ff) @r(70%),
        transparent 0
      )
      @pn(30% 50%, 70% 50%, 50% 60%) /
      @r(.1vmin, 5vmin) @lr()
      no-repeat;
    }

    @place-cell: centerr;
    @size: 100%;

    will-change: transform;
    animation: r 4s linear infinite;
    animation-delay: calc(-4s / @size() * @i());

    --translate: translateY(calc(-66vmin / @size() * @i()));
    @keyframes r {
      from { transform: var(--translate) rotate(0) }
      to { transform: var(--translate) rotateZ(-1turn) }
    }
           `}
          </css-doodle>
        </div>
      </Slide>

      <Slide className="HSworp HS04">
        <div className="Hslide_in">
          <ul>
            <li>
              <p data-aos="fade-up" data-aos-delay="200" className="Htitle">
              CONTINUE
              </p>
              {/* <h1 data-aos="fade-up" data-aos-delay="250" className="Hsubtitle">
                <span className="Hgreenfont">BIO</span>PROTECT
                <span className="Hgreenfont">Us</span> System..
              </h1> */}
              <h2 data-aos="fade-up" data-aos-delay="300" className="Hcopy">
                독보적인 표면 항균코팅 기술은 친환경적인 특성을 유지하며 한번의
                시공으로 최대 90일간 지속적인 보호기능을 제공합니다.
              </h2>
              <br />
              <br />
              <img
                data-aos="fade-right"
                data-aos-delay="350"
                src={continue_icon01}
                width="140px"
                alt="Biodome"
              />
            </li>
            <li data-aos="fade-left" data-aos-delay="1000"></li>
          </ul>
        </div>
        <div className="Hscroll">
          Scroll Down
          <br />
          <br />
          <img src={scroll_icon01} alt="scroll" />
        </div>
        <div className="Hlefttext" data-aos="fade-right" data-aos-delay="2000">
          <div className="Hlefttextin">   {" "}
                지속되다{" "}</div>
        </div>

        <div className="king">
          <css-doodle>
            {`
         :doodle {
          @grid: 8 / 100vmax;

          filter: blur(1px);
          transform: scale(1.5)
        }

        @random {
          border: 1px solid hsla(225, 100%, 40%, @rand(0.1));
        }

        @random {
           filter: blur(1px);
        }

        @random {
          animation: move 5s linear alternate infinite;
        }

        @random(0.1) {
          animation: flicker 5s ease infinite;
        }

        @keyframes move {
          0% {
            transform:	translate(
            @rand(-50%, 50%), @rand(-50%, 50%)
            );
          }

          100% {
            transform:	translate(
            @rand(-50%, 50%), @rand(-50%, 50%)
            );
          }
        }

        @keyframes flicker {
          0% {
            opacity: 1;
          }

          50% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        border-radius: 50%;
        background: hsla(210, 100%, 40%, @rand(0.1));
        transform: scale(@rand(.5, 1.2))
        translate(
        @rand(-50%, 50%), @rand(-50%, 50%)
        );
           `}
          </css-doodle>
        </div>
      </Slide>

      <Slide className="HS08">
        <div className="Hslide_in ">
          <p>biodome은 과학입니다.<br/></p>
          <h1>
            biodome 의 특별한 경험을 누려보세요.<br/>
            biodome 은 쾌적하고 안전한 환경을 약속합니다.</h1>
          <div data-aos="fade-up" data-aos-delay="100">
            <img src={science_im01} alt="science_im01" /><br/>
            <span>교육</span> <br/>학교, 어린이집, 급식소, 놀이터
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <img src={science_im02} alt="science_im02" /><br/>
            <span>의료</span> <br/>종합병원, 요양병원, 복지시설
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <img src={science_im03} alt="science_im03" /><br/>
            <span>상업</span> <br/>백화점, 공연장,영화관, 호텔, 요식업, 까페
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <img src={science_im04} alt="science_im04" /><br/>
            <span>대중교통</span> <br/>항공, KTX, 지하철, 버스, 택시
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <img src={science_im05} alt="science_im05" /><br/>
            <span>스포츠</span> <br/>스타디움, 실내체육관, 골프장, 락커룸
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <img src={science_im06} alt="science_im06" /><br/>
            <span>공간방역</span> <br/>엘리베이터, 로비, 지하주차장
          </div>



        </div>
      </Slide>

      <Slide className="HS09">
        <div className="Hslide_in ">
          <p>인증 및 실험<br/></p>
          <h1>
            이미 다양한 환경에서 BIOPROTECTUs System 의 놀라움을 경험하고 있습니다.</h1>

          <div data-aos="fade-up" data-aos-delay="150">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_04.pdf"} target="_blank"> <img src={certifi_04} alt="certifi_04" /> </a>
            <br/>
            <span>EPA</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_05.pdf"} target="_blank">  <img src={certifi_05} alt="certifi_05" /></a>
            <br/>
            <span>EPA 1 </span>
          </div>

          <div data-aos="fade-up" data-aos-delay="100">
            {/*<img src={certifi_01} alt="certifi_01" onClick={() => window.open("/pdf/cer_01.pdf")}/>*/}
             <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_01.pdf"} target="_blank"> <img src={certifi_01} alt="certifi_01" /> </a>
            <br/>
              <span>안전기준 적합확인(수입)</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_02.pdf"} target="_blank"> <img src={certifi_02} alt="certifi_02" /> </a>
            <br/>
            <span>안전기준 적합확인(제조))</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_03.pdf"} target="_blank">  <img src={certifi_03} alt="certifi_03" /></a>
            <br/>
            <span>안전기준 적합확인대상 결과서</span>
          </div>






          <div data-aos="fade-up" data-aos-delay="100">
            <a href={'https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_07.pdf'} target="_blank"> <img src={certifi_07} alt="certifi_07" /></a>
              <br/>
            <span>BioProtect500 SDS</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_08.pdf"} target="_blank">  <img src={certifi_08} alt="certifi_08" /></a>
              <br/>
            <span>섬유테스트</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_09.pdf"} target="_blank">  <img src={certifi_09} alt="certifi_09" /></a>
              <br/>
            <span>코로나 바이러스 테스트</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_10.pdf"} target="_blank">  <img src={certifi_10} alt="certifi_10" /></a>
              <br/>
            <span>지속성 테스트(Wash)</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_11.pdf"} target="_blank">  <img src={certifi_11} alt="certifi_11" /></a>
              <br/>
            <span>실내테스트(인조잔디, 체육관)</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_06.pdf"} target="_blank">  <img src={certifi_06new} alt="certifi_06new" /></a>
            <br/>
            <span>병원균 테스트</span>
          </div>
          <div data-aos="fade-up" data-aos-delay="150">
            <a href={"https://biodome.s3.ap-northeast-2.amazonaws.com/pdf/cer_12.pdf"} target="_blank">  <img src={certifi_12new} alt="certifi_12new" /></a>
              <br/>
            <span>지속성 테스트</span>
          </div>


        </div>
      </Slide>

      <Slide className="HS06">
        <div className="Hslide_in ">
          <p>
          <span data-aos="fade-up" data-aos-delay="100">정부기관 및 국립시설, 군사시설, 국립학교 등 다양한 환경에서</span><br/>
          <span data-aos="fade-up" data-aos-delay="300">BIOPROTECTUs System의 놀라움을 경험하고 있습니다.</span>
          </p>
          <Swiper
            autoplay={{ delay: 4000 }}
            spaceBetween={60}
            slidesPerView={6}
            // navigation
            pagination={{ clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            centeredSlides={false}
            // scrollbar={{ draggable: true, dragSize: 24 }}
            breakpoints={{
              0: {
                slidesPerView: 3,
                spaceBetween: 0,
                centeredSlides: false,
              },
              500: {
                slidesPerView: 3,
                spaceBetween: 0,
                centeredSlides: false,
              },
              1000: {
                slidesPerView: 6,
                spaceBetween: 20,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im01} alt="alliance_im01" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im02} alt="alliance_im02" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im03} alt="alliance_im03" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im04} alt="alliance_im04" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im05} alt="alliance_im05" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im06} alt="alliance_im06" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im07} alt="alliance_im07" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im08} alt="alliance_im08" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im09} alt="alliance_im09" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im10} alt="alliance_im10" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im11} alt="alliance_im11" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im12} alt="alliance_im12" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Halliance">
                <img src={alliance_im13} alt="alliance_im13" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="Halliance">
                <img src={biospace} alt="biospace" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={dallas_Cowboys} alt="dallas_Cowboys" />
              </div>
            </SwiperSlide>
               <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={jetlinx1} alt="jetlinx1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={lynd} alt="lynd" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={mayo} alt="mayo" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"

              >
                <img src={nets_b} alt="nets_b" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"

              >
                <img src={Nj_Police} alt="Nj_Police" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"

              >
                <img src={N_Roch_school} alt="N_Roch_school" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={nyc_c_a} alt="nyc_c_a" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={paramount} alt="paramount" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={PaymentsJournal2} alt="PaymentsJournal2" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={PR_Newswire} alt="PR_Newswire" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={provisioner} alt="provisioner" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={R_P_S} alt="R_P_S" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={Red_Bull} alt="Red_Bull" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="Halliance"
              >
                <img src={wfmz69} alt="wfmz69" />
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </Slide>

      <Slide className="HS07">
        <Footer />
      </Slide>
    </FullPage>
  );
};

export default Index;
