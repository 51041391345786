import React from "react";
import Lottie from "react-lottie";

type Prop = {
  text?: string;
  options?: any;
}

export default class LoadingComponent extends React.Component<Prop, any> {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: "rgba(0,0,0,0.50)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lottie
          width={200}
          height={150}
          options={
            this.props.options || {
              loop: true,
              autoplay: true,
              animationData: require("../../resources/animations/51-preloader.json"),
            }
          }
        />

        <p style={{ fontSize: 18, fontWeight: "bold", color: "#FFF" }}>
          {this.props.text}
        </p>
      </div>
    )
  }
}