export class User {
  id!: number;
	uuid!: string;
	created_at!: string;
	updated_at!: string;
	deleted_at!: string;
	username!: string;

	constructor(user: User) {
		Object.assign(this, user);
	}
}