import React from "react";
import PermissionRoute from "./PermissionRoute";
import {Redirect, Route, Switch} from "react-router-dom";
import {TYPE_SUPERUSER} from "../constants/user_type";
import Dashboard from "../pages/admin/dashboard";
import NotFound from "../pages/not-found";
import ManageVideo from "../pages/admin/ManageVideo";
import ManageReference from "../pages/admin/ManageReference";
import "../resources/admin.css";
import SignIn from "../pages/admin/SignIn";
const REDIRECT_SIGN_IN = '/admin/sign-in';
const REDIRECT_ADMIN = '/admin';

const authorizedOnly = {
	authorized: true,
	redirect: REDIRECT_SIGN_IN,
	// allowType: [TYPE_SUPERUSER],
};

// For development
const allthorizedOnly = {
  authorized: true,
  unauthorized: true,
};

class Router extends React.PureComponent<any, any> {
  render() {
    const {match} = this.props;
    return (
        <Switch>
          {/*<PermissionRoute title={'biodome - 대시보드'} exact path={match.url} component={Dashboard} {...authorizedOnly}/>*/}
          <PermissionRoute title={'biodome - 대시보드'} exact path={match.url} {...authorizedOnly}>
            <Redirect to={`${match.url}/video`}/>
          </PermissionRoute>
          <PermissionRoute title={'biodome - 관리자'} exact path={`${match.url}/sign-in`} component={SignIn} {...allthorizedOnly}/>
          <PermissionRoute title={'biodome - 비디오 관리'} exact path={`${match.url}/video`} component={ManageVideo} {...authorizedOnly}/>
          <PermissionRoute title={'biodome - 인증 및 실험'} exact path={`${match.url}/reference`} component={ManageReference} {...authorizedOnly}/>
          <Route component={NotFound}/>
        </Switch>
    )
  }
}

export default Router;