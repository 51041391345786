import React from 'react';
import Header from "./header/Header";
import SideBar from "./sidebar/SideBar";
import {withRouter} from "react-router-dom";

const styles = {
  containerStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // height: '100vh'
  },
};

class Container extends React.Component<any, any> {
  render() {
    return (
      <div style={styles.containerStyle}>
        <Header t={this.props}/>

        <div style={{flex: 1, height: '100%', display: 'flex', flexDirection: 'row'}}>
          <SideBar/>

          {/* 화면 내용(헤더 및 사이드바 제외한 영역) 영역 */}
          <div className={"content"} style={{flex: 1, height: 'calc(100vh - 65px)', padding: '65px 60px', overflow: 'auto'}}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Container);