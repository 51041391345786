import React from 'react';
import './index.css';
import './../typewriter.css';
import { Button } from 'react-bootstrap';
import Footer from "../../components/common/Footer";
import reference_img02 from "../../resources/reference_img02.png";
import Navbar from "../../components/common/Navbar";
import {Video} from "../../models/Video";
import {Reference} from "../../models/Reference";
import moment from "moment";
class reference extends React.PureComponent {

  state = {
    data: [],
    isLoading: false,
    page: 1,
    totalCount: 0,
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    }, () => this.fetch(this.state.page));
  }

  fetch = (page) => {
    Reference.getReferenceList(page * 10, 1).then(r => {
      this.setState({
        data: r.rows,
        page: page,
        totalCount: r.count,
      });
    }).catch(err => {
      return alert(err);
    }).finally(() => {
      this.setState({
        isLoading: false,
      })
    })
  }

  renderItem = () =>
    this.state.data?.map((item, index) =>
      <tr key={index}>
        <td>{this.state.totalCount - index}</td>
        <td>{item.title}</td>
        <td><a href={item.attachment_url} className='td_button button_black' target="_blank" download>Download</a></td>
      </tr>
    )

  more = () => {
    this.setState({page: this.state.page + 1}, () => {
      this.fetch(this.state.page)
    })
  }

  render(): JSX.Element {
    return (
      <>
        <Navbar/>

        <div className='referenceSworp reference '>
          <div className='topimg'>
            <img src={reference_img02}  alt="reference_img02"/>
          </div>
          <div className='reference_in'>
            <p>인증 및 실험</p><br/><br/>
            <h1>이미 다양한 환경에서 BIOPROTECTUs System 의 놀라움을 경험하고 있습니다.</h1>
            <table>
              <tbody>
              {this.renderItem()}
              </tbody>
            </table>
            <Button className='big_button button_black' onClick={this.more}>더보기</Button>
          </div>
        </div>
        <Footer/>
      </>


    )
  }
}
export default reference;