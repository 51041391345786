import React from 'react';
import {inject} from "mobx-react";
import Menu from "./Menu";
import MenuGroup from "./MenuGroup";

type Props = {
  containerStyle?: any;
}

const styles = {
  containerStyle: {
    backgroundColor: '#F5F5F5',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 50,
    paddingRight: 50,
    minWidth: 200,
    height: 'calc(100vh - 65px)',
    boxSizing: 'border-box'
  },
};

class SideBar extends React.Component<Props> {

  state = {
    visible_profile_modal: false,
  };

  render() {
    return (
      <div style={styles.containerStyle}>
        <MenuGroup text={'콘텐츠 관리'}>
          <Menu text={'동영상 관리'} to="/admin/video" style={{marginBottom:10}}/>
          <Menu text={'인증 및 실험 관리'} to="/admin/reference" style={{marginBottom:10}}/>
        </MenuGroup>
      </div>
    );
  }
}

export default inject('sessionStore')(SideBar);