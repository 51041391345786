import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import AdminSwitch from "./AdminSwitch";
import PermissionRoute from "./PermissionRoute";
import ScrollToTop from "../components/ScrollToTop";
import Analytics from 'react-router-ga';
import Index from "../pages/index";
import company from "../pages/company";
import reference from "../pages/reference";
import video from "../pages/video";
import NotFound from "../pages/not-found";



class Router extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<ScrollToTop/>
				<Analytics id={'G-1WFD3FZRBV'}>
					<Switch
						atEnter={{ opacity: 0 }}
						atLeave={{ opacity: 0 }}
						atActive={{ opacity: 1 }}
						className="switch-wrapper">
						<PermissionRoute title={'biodome'} path={'/'} exact component={Index} authorized unauthorized/>
						<PermissionRoute title={'biodome'} path={'/company'} component={company} authorized unauthorized/>
						<PermissionRoute title={'biodome'} path={'/reference'} component={reference} authorized unauthorized/>
						<PermissionRoute title={'biodome'} path={'/video'} component={video} authorized unauthorized/>

						<Route path={'/admin'} extact component={AdminSwitch}/>

						<Route path={'/404'} component={Index}/>
						<Route component={NotFound}/>

					</Switch>
				</Analytics>

			</BrowserRouter>

		);
	}
}

export default Router;