import React from 'react';
import {inject} from "mobx-react";

type Props = {
  text: string;
  children?: React.ReactNode;
};

const styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
};

class Menu extends React.Component<Props> {
  render() {
    return (
      <div style={styles.containerStyle}>
        <span style={{fontSize: 12, color: '#909090', marginBottom: 12, }}>{this.props.text}</span>
        {this.props.children}
      </div>
    );
  }
}

export default inject('sessionStore')(Menu);