import React from "react";
import moment from "moment";
import {BsPencilSquare, BsTrash} from "react-icons/bs";
import {api_biodome} from "../../api/api_biodome";
import {Video} from "../../models/Video";
import VideoModal from "./VideoModal";

type Prop = {
  item: Video;
  fetch: Function;
  page: number,
  num: number,
}

export default class VideoListItemComponent extends React.Component<Prop, any> {

  state = {
    isOpen: false,
  }

  delete = () => {
    if(window.confirm('정말 삭제하시겠습니까?')) {
      api_biodome.delete(`/v1/board-video/delete/${this.props.item.uuid}`).then(r => {
        if(!r.ok) return alert('[서버 문제] 삭제할 수 없습니다');
        this.props.fetch(this.props.page);
        return alert('삭제 되었습니다');
      })
    }
  }

  modalHandler = (bol) => {
    this.setState({isOpen: bol});
  }

  render() {
    const {item, num} = this.props;
    return (
      <tr key={num}>
        <td>{num}</td>
        <td>{item?.title}</td>
        <td>{moment.unix(parseInt(item?.created_at)).format("YYYY.MM.DD")}</td>
        <td style={{position: 'relative'}}>
          <div className={"icons"}>
            <BsPencilSquare size={20} style={{marginRight: 10}} className={"icon"} onClick={() => this.modalHandler(true)}/>
            <BsTrash size={20} className={"icon"} onClick={this.delete}/>
          </div>
        </td>
        <VideoModal item={item} key={Math.random()} open={this.state.isOpen} close={this.modalHandler} fetch={this.props.fetch} page={this.props.page} type={"edit"}/>
      </tr>
    );
  }
}