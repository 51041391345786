import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'

import biodome_ci_w from "../../resources/biodome_ci_w.svg";
import btn_bebedome from "../../resources/btn_bebedome.png";
import btn_kakao from "../../resources/btn_kakao.svg";

import $ from "jquery";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const myButton = document.getElementById("myBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    $("#myBtn").css("display","block");
  } else {
    $("#myBtn").css("display","none");
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera


}


class Navbar extends React.Component {


  render() {
    return (
      <div id="header">

      <Menu right id="hamberger">
        <Link to={"/company"}>COMPANY</Link>
        <Link to={"/video"}>동영상</Link>
        <Link to={"/reference"}>인증 및 실험</Link>
      </Menu>

        <div id="myBtn">
        <button onClick={topFunction} className="myBtntop" title="Go to top">Top</button>
        <a href="http://pf.kakao.com/_ZxcTyb" target="_black" className="kakao"><img src={btn_kakao} width="50px" alt="kakao" /></a>
        </div>


        <div className="header-in">
          <div className="logo">
            <Link to={"./"}>
              <img src={biodome_ci_w} width="110px" alt="Biodome" />
            </Link>
            {/* <Link to={'./'}><img src={biodome_ci_b} width="110px" alt="Biodome"/></Link> */}
          </div>

          <div className="menuweb">
            <ul className="gnb">
              <li>
                <Link to={"/company"}>COMPANY</Link>
              </li>
              <li>
                <Link to={"/video"}>동영상</Link>
              </li>
              <li>
                <Link to={"/reference"}>인증 및 실험</Link>
              </li>
            </ul>
          </div>

          <div className="bebe-btn">
            <a href="https://bebedome.co.kr/" target="_blank">
            <img src={btn_bebedome} width="110px" alt="Biodome" />
            </a>
          </div>
        </div>


      </div>
    );
  }
}
export default Navbar;
