import React from "react";
import { Link } from "react-router-dom";
import biodome_ci_w from "../../resources/biodome_ci_w.svg";
import {inject} from "mobx-react";
import LoadingComponent from "./LoadingComponent";

class SignIn extends React.Component<any, any> {

  private isLockedSubmit: any;

  state = {
    username: '',
    password: '',
    isLoading: false,

    error: null,
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  signIn2 = () => {
    if (this.isLockedSubmit) return;
    this.isLockedSubmit = true;

    const release = (state, callback) => {
      this.setState({ isLoading: false, ...state }, () => {
        this.isLockedSubmit = false;
        typeof callback === "function" && callback();
      });
    };

    this.setState({ isLoading: true }, () => {
      const username = this.state.username;
      const password = this.state.password;

      if (!username) return release({ error: "계정을 입력해주세요." }, alert("계정을 입력해주세요"));
      if (!password) return release({ error: "비밀번호를 입력해주세요" }, alert("비밀번호를 입력해주세요"));

      this.props.sessionStore.signIn(username, password, true).then((r) => {
        setTimeout(() => {
          this.props.history.push("/admin");
        }, 1000);
      }).catch((e) => {
        switch (e) {
          case "TOKEN_NOT_EXISTS": {
            release({ error: "로그인 실패(T404)" }, null);
            break;
          }
          default: {
            release({ error: "로그인 실패" }, null);
            break;
          }
        }
        alert("아이디와 비밀번호를 확인해주세요");
        this.props.history.push("/admin");
      });
    });
  }

  render() {
    return (
      <div className={"admin-sign-in"}>
        <div className={"sign-in-wrapper"}>
          <div className={"logo-wrapper"}>
            <div className={"logo-txt"}>관리자</div>
            <img src={biodome_ci_w} className={"logo"} alt="Biodome"/>
          </div>
          <input className={"id"} placeholder={"아이디"} onChange={e => {this.onChange('username', e.target.value)}}/>
          <input className={"pwd"} placeholder={"비밀번호"} type={"password"} onChange={e => {this.onChange('password', e.target.value)}} onKeyPress={e => e.key === 'Enter' && this.signIn2()}/>

          <button className={"btn-login"} onClick={this.signIn2}>로그인</button>
          {this.state.isLoading && <LoadingComponent text={"로그인하는 중 ..."}/>}
        </div>
      </div>
    );
  }
}

export default inject(["sessionStore"])(SignIn);