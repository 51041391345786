import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {JWTUtils} from "../utils/JWTUtils";
import {inject} from "mobx-react";

type Props = {
  path: string;
  exact?: boolean;
  title?: string,
  authorized?: boolean,			// Has accessible permission to Authorized session
  unauthorized?: boolean,		// Has accessible permission to UnAuthorized session
  allowType?: Array<string>,
  redirect?: string,
  component?: any,
  render?: Function,
};

class PermissionRoute extends React.Component<Props, any> {

	static defaultProps = {
		title: 'biodome',
		authorized: false,
		unauthorized: false,
		redirect: '/',
	};

	renderRedirect = (path: string = '', from?: string, params?: Object) => (
		<Redirect to={{ pathname: path, state: { from, ...params } }}/>
	);

	render() {
		const title: string | undefined = this.props.title;
		if (title) {
			document.querySelector('title').innerText = title;		// HTML <title></title> 내용을 변경
		}

		/** Check session is accessible to this route */
		if (!this.props.authorized || !this.props.unauthorized) {		// 인증된 / 인증되지 않은 세션에서 접근 불가능할 경우
			const authenticated = JWTUtils.isAuthenticated();
			if (!this.props.authorized) {		// 인증된 세션에서 접근 불가능할 때
				if (authenticated) {			// 인증된 세션일 때
					return this.renderRedirect(this.props.redirect);
				}
			}

			if (!this.props.unauthorized) {		// 인증되지 않은 세션에서 접근 불가능한 경우
				if (!authenticated) {			// 인증되지 않은 세션일 때
					return this.renderRedirect(this.props.redirect);
				}
			}
		}

		if (this.props.allowType) {
			if (Array.isArray(this.props.allowType)) {
				const sessionStore = this.props.sessionStore;
				if (!this.props.allowType.includes(sessionStore?.user?.type)) {
					return this.renderRedirect('/');
				}
			}
		}

		const {component: Component} = this.props;
		const render = this.props.render;
		return (
			<Route
				{...this.props}
				render={(props) => {
					if (render) return render(props);		// render 함수가 있을 경우
					return <Component {...props} />;
				}}
			/>
		);
	}
}

export default inject('sessionStore')(PermissionRoute);