import {api_biodome} from "../api/api_biodome";

export class Video {
  id!: number;
  uuid!: string;
  title!: string;
  video_url!: string;
  attachment_url!: string;
  attachment_type!: string;
  attachment_originname!: string;
  issued!: boolean;
  created_at!: string;
  modified_at!: string;

  constructor(video: Video) {
    Object.assign(this, video);
  }

  static getVideoList = (limit, page) => new Promise((res, rej) => {
    return api_biodome.get('/v1/board-video', {
      limit: limit,
      page: page,
    }).then(r => {
      if(!r.ok) return rej(`[${r.problem}] 데이터를 가져올 수 없습니다`);
      return res(r.data);
    }).catch(err => {
      return res('[서버문제] 데이터를 가져올 수 없습니다');
    });
  });
};