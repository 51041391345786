import React from 'react';
import './index.css';
import {Link} from "react-router-dom";
import biodome_ci_w from "../../resources/biodome_ci_w.svg";

class NotFound extends React.Component<any, any> {

  MAIN_PAGE_URL = "/";

  render() {
    return (
      <div style={{width: '100%', height: '100vh', backgroundColor: 'black', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Link to={'/admin'}><img src={biodome_ci_w} width="300px" alt="Biodome"/></Link>
        <h1 style={{color: 'white', fontWeight: 'bold', marginTop: 20, fontSize: 25}}>존재하지 않는 페이지입니다.</h1>

        <div style={{marginTop: 50}}>
          <a onClick={() => window.history.go(-1)} style={{color: 'white', cursor: "pointer", marginRight: 30}}>돌아가기</a>
          <a onClick={() => window.location.href=`${this.MAIN_PAGE_URL}`} style={{color: 'white', cursor: "pointer"}}>메인페이지</a>
        </div>
      </div>
    )
  }
}

export default NotFound;