/* eslint-disable import/first */
import React from 'react';
import './index.css';
import './../typewriter.css';
import 'css-doodle';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

import vidotop_img01 from "../../resources/vidotop_img01.png";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";

class company extends React.PureComponent {

  render() {
    return (
      <>
        <Navbar/>

        <div className='companySworp company'>
          <div className='topimg'>
            <img src={vidotop_img01}  alt="vidotop_img01"/>
          </div>

          <div className='company_in'>

            <ul>
              <li>
                <p className='Htitle'>Company</p><br/><br/>
                <h1>  바이러스/박테리아는 인간의 문명보다 더 빠르게 진화하고 있습니다.</h1> <br/>

                이미 160만여 종의 바이러스/박테리아와 공존하고 있지만 소독.살균 분야에만 편향적인
                한국은 방역(지속성)에 특화된 제품이 없으며 기술개발 또한 미진합니다.<br/><br/>

                이를 반영하듯 시중에 판매되는 대부분의 방역제품은 살균제로 사실상
                방역(지속성)의 효과는 상당히 미비합니다<br/><br/>

                <span className='Hgreenfont'>분자과학의 기반을 둔 ViaClean Technologies,LLC.(U.S.A)의<br/>
차세대 항균기술개발로 생산된 최첨단 방역제품 (BIOPROTECT500/RTU) 은<br/>
유해 미생물 및 바이러스로부터 보다 안전한 솔루션(BIOPROTECTUs System)을 제공합니다.</span><br/><br/>

                방역에 대한 인식을 높이고 바이러스.박테리아로 부터 안전한 삶을 만드는 것이 biodome 이 추구하는 목표와 가치입니다. <br/><br/>
              </li>
              <li></li>

            </ul>

          </div>

        </div>
        <Footer/>
      </>
    )
  }
}
export default company;