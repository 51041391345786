import React from "react";
import {api_biodome} from "../../api/api_biodome";
import {Video} from "../../models/Video";

type Props = {
  open: boolean,
  close: Function,
  fetch: Function,
  page: number,
  type?: string,
  item: Video,
}

export default class VideoModal extends React.Component<Props, any> {

  state = {
    title: this.props.type === 'new' ?  '' : this.props.item?.title,
    video_url: this.props.type === 'new' ?  '' : this.props.item?.video_url,
    file: '',
    filename: this.props.type === 'new' ?  '' : this.props.item?.attachment_originname,
  }

  close = () => {
    this.props.close(false);
  }

  handleAttachment = (e) => {
    this.setState({
      file: e.target.files[0],
      filename: e.target.files[0].name,
    });
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  register = () => {
    const {title, video_url} = this.state;
    if(!title) return alert('제목을 입력해주세요');
    if(!video_url) return alert('영상 주소를 입력해주세요');

    let formData = new FormData();
    formData.append('title', this.state.title || '');
    formData.append('video_url', this.state.video_url || '');
    formData.append('issued', '1');
    formData.append('file', this.state.file);

    api_biodome.post('/v1/board-video/register', formData,{
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(r => {
      if(!r.ok) return alert('등록할 수 없습니다');
      alert('등록되었습니다');
      this.close();
      return this.props.fetch(this.props.page);
    }).catch(err => {
      console.log(err);
    })
  }

  edit = () => {
    let formData = new FormData();
    formData.append('title', this.state.title || '');
    formData.append('video_url', this.state.video_url || '');
    formData.append('issued', '1');
    if(this.state.file) formData.append('file', this.state.file);

    api_biodome.put(`/v1/board-video/modify/${this.props.item.uuid}`, formData,{
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(r => {
      if(!r.ok) return alert('수정할 수 없습니다');
      alert('수정 되었습니다');
      this.close();
      return this.props.fetch(this.props.page);
    }).catch(err => {
      console.log(err);
    })
  }

  render() {
    if(!this.props.open) return null;
    return (
      <div className={"admin-modal-wrapper"}>
        <div className={"admin-modal"}>
          {console.log(this.props?.item || '')}
          <div className={"modal-title"}>동영상 추가</div>
          <div className={"content"}>
            <div className={"item"}>
              <span className={"field"}>제목</span>
              <input className={"input"} value={this.state.title} onChange={(e) => this.onChange('title', e.target.value)}/>
            </div>
            <div className={"item"}>
              <span className={"field"}>영상주소</span>
              <input className={"input"} value={this.state.video_url} onChange={(e) => this.onChange('video_url', e.target.value)}/>
            </div>
            <div className={"item"}>
              <span className={"field"}>썸네일</span>
              <div className={"input-file-wrapper"}>
                <input className={"input"} disabled value={this.state.filename}/>
                <label className={"btn file"} htmlFor="file">첨부하기</label>
                <input type="file" id="file" onChange={this.handleAttachment}/>
              </div>
            </div>
          </div>
          <div className={"modal-btn-wrapper"}>
            <div className={"btn modal-btn-cancel"} onClick={this.close}>취소</div>
            {
              this.props.type === 'new' ?
                <div className={"btn modal-btn-add"} onClick={this.register}>등록</div>
                :
                <div className={"btn modal-btn-add"} onClick={this.edit}>수정</div>
            }
          </div>
        </div>
      </div>
    );
  }
}