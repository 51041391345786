import React from 'react';
import {inject} from "mobx-react";
import {Link} from 'react-router-dom';

type Props = {
  text: string;
  to: string;
  style: any;
};

const styles = {
  containerStyle: {
    display: 'flex',
  },
};

class Menu extends React.Component<Props> {
  render() {
    return (
      <div style={{...styles.containerStyle, ...this.props.style}}>
        <Link style={{ fontSize: 18, color: '#000', textDecoration: 'none' }} to={this.props.to}>
          {this.props.text}
        </Link>
      </div>
    );
  }
}

export default inject('sessionStore')(Menu);