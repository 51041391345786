import React from 'react';
import './index.css';
import { Button } from 'react-bootstrap';
import alliance_img01 from "../../resources/alliance_img01.png";

import Footer from "../../components/common/Footer";
import reference_img03 from "../../resources/reference_img03.png";
import Navbar from "../../components/common/Navbar";
import {Video} from "../../models/Video";
import moment from "moment";
class vido extends React.PureComponent {

  state = {
    data: [],
    isLoading: false,
    page: 1,
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    }, () => this.fetch(this.state.page));
  }

  fetch = (page) => {
    Video.getVideoList(page * 10, 1).then(r => {
      this.setState({data: r, page: page});
    }).catch(err => {
      return alert(err);
    }).finally(() => {
      this.setState({
        isLoading: false,
      })
    })
  }

  renderItem = () =>
    this.state.data?.rows?.map(item =>
      <ul key={item.no} onClick={() => {window.open(item.video_url)}}>
        <li><img src={item.attachment_url} alt="alliance01"/></li>
        <li>{moment.unix(item.created_at).format("YYYY-MM-DD")}</li>
        <li>{item.title}</li>
      </ul>
    )

  more = () => {
    this.setState({page: this.state.page + 1}, () => {
      this.fetch(this.state.page)
    })
  }

  render() {
    return (
      <>
        <Navbar/>
        <div className='vidoSworp vido '>
          <div className='topimg'>
            <img src={reference_img03}  alt='video_img03'/>
          </div>
          <div className='vido_in'>
            <p>동영상</p><br/><br/>
            <div className='Vidolist'>
              {this.renderItem()}
            </div>
            <Button onClick={this.more} style={{cursor: 'pointer'}}>더보기</Button>
          </div>
        </div>
        <Footer/>
      </>
    )
  }
}
export default vido;