import React from 'react';
import "./Footer.css";


class Footer extends React.Component {
    render() {
        return (

         <div id="footer">
          <div className="footer-in">
          {/* <div className="logo"><Link to={'./'}><img src={biodome_ci_w} width="140px" alt="Biodome"/></Link></div> */}
            

            {/* 개인정보처리방침  |  서비스이용약관<br/> */}
            바이오돔 |  본사 : 부산시 해운대구 해운대로 191 번길 27 (센텀 S 빌딩) 202 호  |  대표 : 이현수<br/>
사업자 등록번호 : 855-88-02202 / 전화번호 : 1600.1508<br/><br/>

 Copyright © 2022 biodome. All rights reserved.<br/>
          
          </div>
          </div>
        );
      }
    }
 export default Footer;