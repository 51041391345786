import React from "react";
import VideoModal from "../../components/admin/VideoModal";
import Container from "../../components/admin/Container";
import {Video} from "../../models/Video";
import LoadingComponent from "./LoadingComponent";
import VideoListItemComponent from "../../components/admin/VideoListItemComponent";
import {Pagination, Stack} from "@mui/material";
import ReferenceListItemComponent from "../../components/admin/ReferenceListItemComponent";

export default class ManageVideo extends React.Component {

  state = {
    isOpen: false,
    data: [],
    isLoading: false,
    page: 1,
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    }, () => this.fetch(this.state.page));
  }

  modalHandler = (bol) => {
    this.setState({isOpen: bol});
  }

  fetch = (page) => {
    Video.getVideoList(10, page).then(r => {
      this.setState({data: r, page: page});
    }).catch(err => {
      return alert(err);
    }).finally(() => {
      this.setState({
        isLoading: false,
      })
    })
  }

  renderItem = () =>
    this.state.data?.rows?.map(item =>
      <VideoListItemComponent item={item} fetch={this.fetch} page={this.state.page}/>
    )

  renderItem = () => {
    const currentPage = this.state.data.current_page ? (this.state.data.current_page -1) : 0;
    const len = this.state.data.count - ((currentPage) * 10);
    return (
      this.state.data?.rows?.map((item, index) =>
        <VideoListItemComponent num={len - index} item={item} fetch={this.fetch} page={this.state.page}/>
      )
    )
  }
  render() {
    return (
      <Container>
        <div className={"admin admin-video"}>
          <div className={"title"}>동영상 관리</div>
          <div className={"btn-wrapper"}>
            <button className={"btn"} onClick={() => this.modalHandler(true)}>등록</button>
          </div>
          <table
            className="admin-table"
            width="100%"
            cellSpacing="0"
            cellPadding="0"
          >
            <thead>
            <tr>
              <td scope="col" style={{width: 100}}>번호</td>
              <td scope="col">제목</td>
              <td scope="col" style={{width: 150}}>작성일자</td>
              <td scope="col" style={{width: 150}}>관리</td>
            </tr>
            </thead>
            <tbody>
            {this.renderItem()}
            {this.state.data?.rows?.length <= 0 &&
            <tr>
              <td colSpan={5} style={{padding: '100px 0'}}>데이터가 없습니다</td>
            </tr>
            }
            </tbody>
          </table>

          <div style={{textAlign: 'center', margin: '30px 0'}}>
            <Pagination count={this.state.data?.total_pages} variant="outlined" shape="rounded" style={{display: 'inline-block'}} onChange={(e, value) => this.fetch(value)}/>
          </div>

          <VideoModal key={Math.random()} open={this.state.isOpen} close={this.modalHandler} fetch={this.fetch} page={this.state.page} type={"new"}/>
          {this.state.isLoading && <LoadingComponent text={"데이터 가져오는 중..."}/>}
        </div>
      </Container>
    )
  }
}