import React from 'react';
import './App.css';
import Router from "./routes";
import {Provider} from 'mobx-react';
import stores from './stores';
import {api_biodome} from "./api/api_biodome";
import {StorageUtils} from "./utils/StorageUtils";

api_biodome.setHeader("Authorization", StorageUtils.getAuthorization());

function App() {
  return (
    <Provider {...stores}>
      <Router/>
    </Provider>
  );
}

export default App;